import { ReactNode } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Link as MaterialLink } from "@mui/material";

interface LinkProps {
  to: string;
  children: ReactNode;
}

const Link = ({ to, children }: LinkProps) => {
  return (
    <MaterialLink component={RouterLink} to={to}>
      {children}
    </MaterialLink>
  );
};

export default Link;
