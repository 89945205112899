// @ts-nocheck
import React, { useEffect, useState, useRef } from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import Link from "../Link";
// import OverlayType = google.maps.drawing.OverlayType;
// import DrawingManagerOptions = google.maps.drawing.DrawingManagerOptions;

// const drawingManagerOptions = {
//   drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
// } as DrawingManagerOptions;

const otherRouteOne = [
  { lat: 51.146799605689736, lng: -114.24355889427177 },
  { lat: 51.14626116331688, lng: -114.243419419403 },
  { lat: 51.14559483218413, lng: -114.24337650405876 },
  { lat: 51.1451909904523, lng: -114.24321557151787 },
  { lat: 51.14493522219464, lng: -114.24411679374687 },
  { lat: 51.145702522715865, lng: -114.24642349349968 },
  { lat: 51.14653711581766, lng: -114.2473998175811 },
  { lat: 51.147062094068964, lng: -114.2462303744506 },
  { lat: 51.146644804150895, lng: -114.24564028846733 },
  { lat: 51.1468467190985, lng: -114.2451682196807 },
];

const otherRouteTwo = [
  { lat: 51.14677831181065, lng: -114.24043521160652 },
  { lat: 51.14617256342008, lng: -114.24031719440987 },
  { lat: 51.145701420286684, lng: -114.24056395763924 },
  { lat: 51.14545911623114, lng: -114.24056395763924 },
  { lat: 51.145142772910916, lng: -114.24009188885262 },
  { lat: 51.143749490357784, lng: -114.24011334652474 },
  { lat: 51.14343986630133, lng: -114.23854505066676 },
  { lat: 51.144234115146304, lng: -114.23823391442103 },
  { lat: 51.14489373515487, lng: -114.23782621865077 },
  { lat: 51.144873542845474, lng: -114.23749362473292 },
  { lat: 51.144846619752556, lng: -114.23716103081507 },
  { lat: 51.14527107084192, lng: -114.2364095171111 },
  { lat: 51.146307589051325, lng: -114.23618421155385 },
  { lat: 51.14612586351553, lng: -114.23451051312855 },
  { lat: 51.1467181515164, lng: -114.23453197080067 },
  { lat: 51.14675180401552, lng: -114.23623785573415 },
  { lat: 51.14657681075211, lng: -114.23674211102895 },
  { lat: 51.14683256991333, lng: -114.23704251843861 },
];

const otherRoutes = [otherRouteOne, otherRouteTwo];

const render = (status: Status) => {
  if (status === Status.FAILURE) {
    return <p>Status: Failure</p>;
  }

  return <p>Status: Loading</p>;
};

const Mapper = () => {
  const mapContainerRef = useRef();
  const drawingManagerRef = useRef();

  const [polygonCoordinates, setPolygonCoordinates] = useState([]);
  const [polygon, setPolygon] = useState(null);

  const handlePolygonComplete = (polygon, drawingManager) => {
    const coords = polygon
      .getPath()
      .getArray()
      .map((coord) => {
        return {
          lat: coord.lat(),
          lng: coord.lng(),
        };
      });

    // turn drawing off
    drawingManager.setDrawingMode(null);

    setPolygonCoordinates(coords);
    setPolygon(polygon);

    // hide the drawing controls
    drawingManagerRef.current.setOptions({
      drawingControl: false,
    });
  };

  useEffect(() => {
    // @ts-ignore
    const map = new window.google.maps.Map(mapContainerRef.current, {
      center: { lat: 51.14685434696549, lng: -114.23715914356222 },
      zoom: 17,
    });

    const drawingManager = new window.google.maps.drawing.DrawingManager({
      drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
      drawingControl: true,
      drawingControlOptions: {
        position: window.google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [window.google.maps.drawing.OverlayType.POLYGON],
      },
    });

    drawingManagerRef.current = drawingManager;

    window.google.maps.event.addListener(
      drawingManager,
      "polygoncomplete",
      (polygon) => handlePolygonComplete(polygon, drawingManager)
    );

    drawingManager.setMap(map);

    otherRoutes.forEach((paths) => {
      // Draw other routes:
      const existingMap = new window.google.maps.Polygon({
        paths: paths,
        strokeColor: "#000000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#8fa0e3",
        fillOpacity: 0.35,
      });

      existingMap.setMap(map);
    });
  }, []);

  const handleClearButtonClick = () => {
    if (polygon) {
      polygon.setMap(null);
      setPolygon(null);
    }

    // turn drawing back on
    drawingManagerRef.current.setDrawingMode(
      window.google.maps.drawing.OverlayType.POLYGON
    );

    // show the drawing controls
    drawingManagerRef.current.setOptions({
      drawingControl: true,
    });
  };

  const coordinatesAsString = polygonCoordinates
    .map((coord) => `${coord.lat}/${coord.lng}`)
    .join(", ");

  return (
    <>
      <h1>Welcome to the map experiment</h1>
      <div>
        <Link to="/">Back to Home</Link>
      </div>
      <div style={{ height: "650px" }} ref={mapContainerRef} id="map" />
      <button type="button" onClick={handleClearButtonClick}>
        Clear
      </button>
      <div style={{ textAlign: "left" }}>{coordinatesAsString}</div>
    </>
  );
};

const RouteMapEditorPage = () => {
  return (
    <Wrapper
      apiKey={"AIzaSyBWEeJG2MlTJUyJQRIH-hdTCzbwxHAjICM"}
      libraries={["drawing"]}
      render={render}
    >
      <Mapper />
    </Wrapper>
  );
};

export default RouteMapEditorPage;
