import styled from "@emotion/styled";
import { TextField } from "@mui/material";

const PageLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; // This will need to change once there is app-level layout.
  width: 100%;
`;

const EmailPasswordFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const LoginTextField = styled(TextField)`
  width: 25rem;
`;

export { PageLayout, EmailPasswordFieldContainer, LoginTextField };
