import { Routes, Route } from "react-router-dom";
import LoginPage from "./components/pages/LoginPage";
import IndexPage from "./components/pages/IndexPage";
import RouteMapEditorPage from "./components/pages/RouteMapEditorPage";

const ApplicationRoutes = () => {
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/route" element={<RouteMapEditorPage />} />
      <Route path="/" element={<IndexPage />} />
    </Routes>
  );
};

export default ApplicationRoutes;
