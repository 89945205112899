import Link from "../Link";

const IndexPage = () => {
  return (
    <div>
      <Link to="/route">Route</Link>
    </div>
  );
};

export default IndexPage;
