import axios from "axios";
import { serverHost } from "lib/configuration";

const login = async (email: string, password: string) => {
  console.log("login top", email, password);

  const params = {
    email,
    password,
  };

  console.log("serverHost", serverHost);

  const response = await axios.post(`${serverHost}/auth/authenticate`, params);

  console.log("response", response.data);

  return response;
};

export { login };
