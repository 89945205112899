import { FieldValues, useForm } from "react-hook-form";
import { Button } from "@mui/material";
import { login } from "api/login";
import {
  LoginTextField,
  EmailPasswordFieldContainer,
  PageLayout,
} from "./LoginPages.styles";
import { useMutation } from "react-query";

interface LoginParameters {
  email: string;
  password: string;
}

const LoginPage = () => {
  const { register, handleSubmit } = useForm();

  const { mutate } = useMutation((loginParameters: LoginParameters) =>
    login(loginParameters.email, loginParameters.password)
  );

  const handleFormSubmit = (data: FieldValues) => {
    const params: LoginParameters = {
      email: data.email,
      password: data.password,
    };

    mutate(params);
  };

  return (
    <PageLayout>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <EmailPasswordFieldContainer>
          <LoginTextField label="Email" {...register("email")} />
          <LoginTextField
            label="Password"
            type="password"
            {...register("password")}
          />
          <Button type="submit">Login</Button>
        </EmailPasswordFieldContainer>
      </form>
    </PageLayout>
  );
};

export default LoginPage;
